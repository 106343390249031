<template>
    <b-container fluid id="fileAndImage">
        <!-- No Data Section -->
        <div v-if="grouppedMedias.length == 0" class="mb-3">
            <no-data></no-data>
        </div>

        <!-- Multimedia Section -->
        <div v-else>
            <div v-for="(grouppedMedia, index) in grouppedMedias" :key="index" class="iq-card iq-card-block iq-card-stretch iq-card-height shadow-sm pt-3">
                <div class="iq-card-header d-flex justify-content-between mb-2 border-bottom">
                    <div class="iq-header-title d-flex flex-inline align-items-center mb-2">
                        <span class="border-right p-3 mr-2 d-flex justify-content-center align-items-center">
                            <i class="fa fa-photo-video fa-2x"></i>
                        </span>
                        <div class="d-flex flex-column">
                            <span v-if="grouppedMedia.notes" class="mb-1">
                                <strong>{{ grouppedMedia.notes }}</strong>
                            </span>
                            <div class="d-flex flex-inline justify-content-between">
                                <div class="mr-3">
                                    <i class="fa fa-calendar mr-1"></i>
                                    <small><strong>{{ grouppedMedia.date ? grouppedMedia.date : grouppedMedia.created }}</strong></small>
                                </div>

                                <div v-if="grouppedMedia.uploadedBy">
                                    <i class="fa fa-user mr-1"></i>
                                    <small><strong>{{ grouppedMedia.uploadedBy }}</strong></small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body iq-card-body">
                    <div class="row px-2">
                        <div v-for="(media, mediaindex) in grouppedMedia.multimedias" :key="mediaindex" class="col-md-3">
                            <div v-if="checkMultimediaIsImage(media.fileExtension)" class="col-md-12 thumb my-3">
                                <div class="imgStyle">
                                    <div :class="multimediaType == 'SensitiveContent' && media.isBlurred ? 'blurStyle' : 'sizeStyle'">
                                        <a :href="media.url" class="thumbnail"
                                           :data-image-id="media.id"
                                           :data-title="media.fileName"
                                           :data-image="media.url"
                                           target="_blank">
                                            <img :id="'img_' + media.id" @error="generateThumbnailImage(media.id)" class="img-thumbnail multimediaStyle" :src="media.thumbnailUrl" />
                                        </a>
                                    </div>
                                    <div v-if="multimediaType == 'SensitiveContent' && media.isBlurred" class="blurContent thumbnail d-flex">
                                        <div style="align-self:flex-end;">
                                            <i class="fa fa-eye-slash text-white" style="font-size:2.4rem !important" />
                                        </div>
                                        <div style="align-self:flex-end;">
                                            <hr style="border-color:#fff !important" />
                                            <button type="button" @click="showImage(media.id,index)"
                                                    style="background-color: rgb(255 255 255 / 70%) !important;"
                                                    class="btn btn-light btn-elevate-hover btn-widest btn-sm">
                                                {{ $t('Show') }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div v-if="!media.isBlurred">
                                    <router-link :to="{name: 'sketch.multimedia', params: { multimediaId: media.id, customerId: customerId }}"
                                           data-toggle="tooltip">
                                        <button class="btn btn-sm mr-2 btn-success">
                                          <i class="fa fa-paint-brush text-white" />
                                        </button>
                                    </router-link>

                                    <button @click="showDeleteConfirmation(media.id)" class="btn btn-sm mr-2 btn-danger">
                                        <i class="fa-solid fa-trash-can text-white"></i>
                                    </button>

                                    <button v-if="multimediaType == 'SensitiveContent' && !media.isBlurred"
                                            @click="hideImage(media.id,index)" class="btn btn-sm btn-primary">
                                        <i class="fa-solid fa-eye-slash text-white"></i>
                                    </button>
                                </div>
                            </div>
                            <div v-else
                                 class="col-md-12 thumb"
                                 style=" margin-top: 15px !important;margin-bottom: 15px !important; ">
                                <div>
                                    <a :href="media.url" class="thumbnail"
                                       :data-image-id="media.id"
                                       data-toggle="tooltip"
                                       :data-title="media.fileName"
                                       :data-image="media.url"
                                       target="_blank">
                                        <img class="img-thumbnail" alt="" style="height: 200px;"
                                             :src="'https://via.placeholder.com/165x165?text=' + media.fileExtension" />
                                    </a>
                                </div>
                                <div>
                                    <button class="btn btn-sm mt-3 btn-danger" @click="showDeleteConfirmation(media.id)">
                                        <i class="fa-solid fa-trash-can text-white"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div ref="customerMultimedia_refresher"
             id="customerMultimedia_refresher"
             @click="getMultimedias()" />
    </b-container>
</template>

<script>
  import { deleteConfirmation } from '../../../helpers/swalConfirmations';
  import NoData from '../../../components/shared/NoData';

  import multimediaService from '../../../services/multimedia';
  import '../../../plugins/sweetalert';

  export default {
    name: 'FileUpload',
    props: {
      customerId: String,
      multimediaType: String
    },
    components: {
      NoData
    },
    data() {
      return {
        grouppedMedias: [],
        selectedMultimediaIdList: [],
        selectedGroupList: [],
        objectDeleteUrl: "/Multimedia/DeleteMultimedia"
      }
    },
    methods: {
      getMultimedias() {
        multimediaService.getMultimedia(this.customerId, this.multimediaType)
          .then((response) => {
            this.grouppedMedias = response;
            this.selectedMultimediaIdList = [];
            this.selectedGroupList = [];
          });
      },
      generateThumbnailImage(multimediaId) {
        multimediaService.generateThumbnailImage(multimediaId).then(response => {
          const thumbnailUrl = response;
          if (thumbnailUrl.length > 0) {
            document.getElementById(`img_${multimediaId}`).src = thumbnailUrl;
          }
        })
      },
      checkMultimediaIsImage(fileExtension) {
        fileExtension = fileExtension.toLowerCase();
        var isImage = fileExtension.includes('jpg') || fileExtension.includes('png')
          || fileExtension.includes('jpeg') || fileExtension.includes('jfif')
          || fileExtension.includes('gif') || fileExtension.includes('tiff');
        return isImage;
      },
      showImage(id, index) {
        this.grouppedMedias[index].multimedias.filter(z => z.id == id).map(function (v) {
          return v.isBlurred = false;
        });
      },
      hideImage(id, index) {
        this.grouppedMedias[index].multimedias.filter(z => z.id == id).map(function (v) {
          return v.isBlurred = true;
        });
      },
      showDeleteConfirmation(objectId) {
        if (objectId) {
            deleteConfirmation(objectId, this.objectDeleteUrl);
        }
      }
    },
    mounted() {
      this.getMultimedias();
    }
  }
</script>

<style scoped>
  #fileAndImage .imgStyle {
    height: 250px !important;
    width: 250px !important;
    background-color: #fff;
    border-radius: 0.25rem;
    border: 1px solid #dee2e6;
    display: table-cell;
    vertical-align: middle;
    position: relative;
  }

  #fileAndImage .sizeStyle {
    height: 250px !important;
    width: 250px !important;
    display: table-cell;
    vertical-align: middle;
    position: relative;
  }

  #fileAndImage .multimediaStyle {
    /*  max-height: 250px !important;
    max-width: 250px !important;*/
    display: block;
    margin: 0 auto;
    border: none !important;
    border-radius: unset !important;
    max-height: 250px !important;
  }

  #fileAndImage .blurStyle {
    filter: blur(20px);
    -webkit-filter: blur(20px);
    /* Full height */
    height: 100%;
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #fileAndImage .blurContent {
    /*background-color: rgb(0,0,0);*/ /* Fallback color */
    background-color: rgba(0,0,0, 0.2);
    color: white;
    /* font-weight: bold;*/
    /* border: 3px solid #f1f1f1;*/
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*   z-index: 2;*/
    width: 100%;
    height: 100%;
    padding: 15px;
    text-align: center;
    display: grid !important;
  }
</style>
