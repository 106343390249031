<template>
  <b-container fluid>
    <Loader v-if="!isContentLoaded" :is-visible="!isContentLoaded"></Loader>
    <div v-else>
      <!-- No Data Section -->
      <div v-if="sketchList.length == 0" class="mb-3">
        <no-data></no-data>
      </div>

      <!-- Multimedia Section -->
      <b-row v-else>
        <b-col md="12" v-for="(item,index) in sketchList" :key="index" class="iq-card iq-card-block iq-card-stretch iq-card-height shadow-sm pt-3">
          <div class="iq-card-header d-flex justify-content-between mb-2 border-bottom">
            <div class="iq-header-title d-flex flex-inline align-items-center mb-2">
              <span class="mr-1 text-dark"><font-awesome-icon icon="fa-regular fa-calendar-days" /> {{$t('SketchDate')}}: </span>
              <span class="line-height-4 text-primary mr-4">{{ item.createdToStr }}</span>
              <span v-if="item.dateToStr" class="mr-1 text-dark"><font-awesome-icon icon="fa-regular fa-calendar-check" /> {{$t('AppointmentDate')}}: </span>
              <span v-if="item.dateToStr" class="line-height-4 text-primary">{{ item.dateToStr }}</span>
            </div>
          </div>
          <div class="card-body iq-card-body d-flex justify-content-between">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-sm-12">
                <img :src="item.url" alt="" class="img-fluid">
              </div>
              <div class="col-md-6 col-sm-12 mt-md-0 mt-3">
                <div v-for="(d,index) in item.sketchDetails" :key="index" class="">
                  <b-list-group flush>
                    <b-list-group-item class="p-0 py-1 text-dark border-bottom bottom-dotted">{{ d.entityName }}</b-list-group-item>
                  </b-list-group>
                </div>
              </div>
              <div class="col-md-3 d-flex justify-content-sm-center justify-content-lg-end mt-md-0 my-4">
                <div class="mr-2">
                  <button @click="download(item.id)" class="btn btn-success btn-icon btn-icon-sm btn-sm">
                    <i class="fa fa-cloud-download-alt"></i>
                  </button>
                </div>
                <div class="mr-2">
                  <button class="btn btn-info btn-icon btn-icon-sm btn-sm" v-on:click="editSketch(item.id)">
                    <i class="fa fa-paint-brush" />
                  </button>
                </div>
                <div style="min-width:40px;" v-if="item.notes && item.notes.length > 0">
                  <note-preview :note="item.notes"></note-preview>
                </div>
                <div class="mr-2">
                  <a v-bind:href="item.url" class="btn btn-primary btn-icon btn-icon-sm btn-sm" target="_blank">
                    <i class="fa fa-search-plus" />
                  </a>
                </div>
                <div>
                  <button class="btn btn-danger btn-icon btn-icon-sm btn-sm" @click="deleteCustomerSketch(item.id)">
                    <i class="fa fa-times" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>

    <div ref="customerSketch_refresher"
         id="customerSketch_refresher"
         @click="getCustomerSketchList()" />
  </b-container>
</template>
<script>
  import Loader from '../../../components/shared/Loader';
  import NoData from '../../../components/shared/NoData';
  import NotePreview from '../../../components/shared/NotePreview';
  import { deleteConfirmation } from '../../../helpers/swalConfirmations';
  import { replaceTurkishCharacters } from '../../../utils/stringExtensions';

  import '../../../plugins/sweetalert';
  import customerSketchService from '../../../services/customerSketch';

  export default {
    name: 'TreatmentSketchs',
    props: {
      customerId: String
    },
    components: {
      NoData,
      Loader,
      NotePreview
    },
    data() {
      return {
        sketchList: [],
        isContentLoaded: false,
      }
    },
    methods: {
      getCustomerSketchList() {
        customerSketchService.getCustomerSketches(this.customerId)
          .then(response => {
            this.sketchList = response;

          }).finally(() => this.isContentLoaded = true);
      },
      deleteCustomerSketch(sketchId) {
        if (sketchId) {
          deleteConfirmation(sketchId, "/customerSketch/delete")
        }
      },
      createBill(sketchId, appointmentId) {
        if (appointmentId) {
          this.$router.push({ name: 'billNewForCustomerSketch', params: { customerSketchId: sketchId, appointmentId: appointmentId, customerId: this.$parent.customerId } })
        }
        else {
          this.$router.push({ name: 'billNewForCustomerSketch', params: { customerSketchId: sketchId, customerId: this.$parent.customerId } })
        }
      },
      editSketch(sketchId) {
        this.$router.push({ name: 'sketch.edit', params: { customerSketchId: sketchId, customerId: this.customerId } })
      },
      download(sketchId) {
        this.$toastr.warning(this.$t("FileDownloadStarted"));

        customerSketchService.downloadTreatmentSketch({ sketchId: sketchId, customerId: this.customerId }, { responseType: "arraybuffer" })
          .then((response) => {
            this.$toastr.remove();
            this.$toastr.success(this.$t("FileDownloadFinished"));

            var filename = "";
            var disposition = response.headers['content-disposition'];
            if (disposition && disposition.indexOf('attachment') !== -1) {
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(disposition);
              if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
            }

            filename = replaceTurkishCharacters(filename);

            var blob = new Blob([response.data], { type: ("application/pdf") });
            var reader = new FileReader();
            reader.fileName = filename;
            reader.onloadend = function () {
              var downloadLink = document.createElement("a");
              downloadLink.href = reader.result;
              downloadLink.download = filename;
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
            }
            reader.readAsDataURL(blob);
          });
      }
    },
    mounted() {
      this.getCustomerSketchList();
    }
  }
</script>
<style scoped>
  .bottom-dotted {
    border: dotted;
  }
</style>
