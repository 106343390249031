<template>
  <b-container fluid>
    <b-row id="sketchTemplate">
      <b-col lg="3" v-for="(template,index) in sketchTemplateList" :key="index">
        <iq-card body-class="text-center" class="bg-light text-white">
          <template v-slot:body>
            <div class="img-fluid d-flex align-items-center justify-content-center" style="height:250px !important; background-color:#FFFFFF !important">
              <img class="imgStyle" :src="template.imagePath" alt="image" style="max-height:100%; max-width:100%;">
            </div>
            <router-link :to="{ name: 'sketch.new', params: { sketchTemplateId : template.id, customerId: customerId }}">
              <b-button class="bg-primary mt-2 btn-block">
                <i class="fa fa-paint-brush mr-2"></i> {{ $t('Draw') }}
              </b-button>
            </router-link>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import multimediaService from '../../../services/multimedia';

  export default {
    name: 'SketchTemplate',
    props: {
      customerId: String
    },
    data() {
      return {
        sketchTemplateList: []
      }
    },
    methods: {
      getSketchTemplateList() {
        multimediaService.getCustomerSketchTemplates().then(response => {
          this.sketchTemplateList = response;
        });
      },
      createLink(imagePath) {
        return window.location.origin + imagePath;
      }
    },
    mounted() {
      this.getSketchTemplateList();
    }
  }
</script>
<style>
  #sketchTemplate .imgStyle {
    border-radius: 0px;
    max-width: 100%;
    max-height: none;
  }
</style>
