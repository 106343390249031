<template>
  <b-container fluid>
    <b-row id="customer-summary">
      <b-col md="12">
        <div class="d-flex justify-content-end align-items-center">
          <b-dropdown id="new-actions-dropdown" :text="$t('Actions')" variant="white" class="mb-4 shadow-sm bg-white rounded-lg">
            <b-dropdown-item :to="{ name: 'multimedia.fileUpload', params: { customerId: customerId } }">{{$t('NewFileOrPhoto')}}</b-dropdown-item>
          </b-dropdown>
        </div>
      </b-col>

      <b-col md="12">
        <iq-card>
          <template v-slot:body>
            <tab-nav :tabs="true" id="myTab-1">
              <tab-nav-items v-if="$store.getters['Auth/checkPermission']('multimedia_show')"
                             :active="true" id="file-photos-tab" ariaControls="FileAndPhotos"
                             role="tab" :ariaSelected="true" :title="$t('FileAndPhoto')"
                             @click="activeTab = 'FileAndPhotos'" />

              <tab-nav-items v-if="$store.getters['Auth/checkPermission']('multimedia_medicalrecords')"
                             :active="false" id="medical-records-tab" ariaControls="MedicalRecords"
                             role="tab" :ariaSelected="false" :title="$t('MedicalRecords')"
                             @click="activeTab = 'MedicalRecords'" />

              <tab-nav-items v-if="$store.getters['Auth/checkPermission']('multimedia_sensitivecontent')"
                             :active="false" id="sensitive-content-tab" ariaControls="SensitiveContent"
                             role="tab" :ariaSelected="false" :title="$t('SensitiveContent')"
                             @click="activeTab = 'SensitiveContent'" />

              <tab-nav-items v-if="$store.getters['Auth/checkPermission']('customer_sketchshow')"
                             :active="false" id="treatment-sketchs-tab" ariaControls="TreatmentSketchs"
                             role="tab" :ariaSelected="false" :title="$t('TreatmentSketchs')"
                             @click="activeTab = 'TreatmentSketchs'" />

              <tab-nav-items v-if="$store.getters['Auth/checkPermission']('customer_sketchshow')"
                             :active="false" id="sketch-template-tab" ariaControls="SketchTemplate"
                             role="tab" :ariaSelected="false" :title="$t('SketchTemplate')"
                             @click="activeTab = 'SketchTemplate'" />
            </tab-nav>
            <tab-content id="myTabContent">
              <tab-content-item :active="activeTab === 'FileAndPhotos'" id="FileAndPhotos" aria-labelled-by="file-photo-tab">
                <component :is="'FileAndPhotos'" v-if="activeTab === 'FileAndPhotos'" :customerId="customerId" :multimedia-type="'FileAndPhotos'" />
              </tab-content-item>
              <tab-content-item :active="activeTab === 'MedicalRecords'" id="MedicalRecords" aria-labelled-by="medical-records-tab">
                <component :is="'FileAndPhotos'" v-if="activeTab === 'MedicalRecords'" :customerId="customerId" :multimedia-type="'MedicalRecords'" />
              </tab-content-item>
              <tab-content-item :active="activeTab === 'SensitiveContent'" id="SensitiveContent" aria-labelled-by="sensitive-content-tab">
                <component :is="'FileAndPhotos'" v-if="activeTab === 'SensitiveContent'" :customerId="customerId" :multimedia-type="'SensitiveContent'" />
              </tab-content-item>
              <tab-content-item :active="activeTab === 'TreatmentSketchs'" id="TreatmentSketchs" aria-labelled-by="treatment-sketchs-tab">
                <component :is="activeTab" v-if="activeTab === 'TreatmentSketchs'" :customerId="customerId" :multimedia-type="'TreatmentSketchs'" />
              </tab-content-item>
              <tab-content-item :active="activeTab === 'SketchTemplate'" id="SketchTemplate" aria-labelled-by="sketch-template-tab">
                <component :is="activeTab" v-if="activeTab === 'SketchTemplate'" :customerId="customerId" :multimedia-type="'SketchTemplate'" />
              </tab-content-item>
            </tab-content>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
  import TabNav from '../../../components/xray/tab/tab-nav'
  import TabNavItems from '../../../components/xray/tab/tab-nav-items'
  import TabContent from '../../../components/xray/tab/tab-content'
  import TabContentItem from '../../../components/xray/tab/tab-content-item'

  import FileAndPhotos from '../Multimedia/FileAndPhotos'
  import TreatmentSketchs from '../Multimedia/TreatmentSketchs'
  import SketchTemplate from '../Multimedia/SketchTemplate'

  export default {
    name: 'SummaryForms',
    props: {
      customerId: String
    },
    components: {
      TabNav,
      TabNavItems,
      TabContent,
      TabContentItem,
      FileAndPhotos,
      TreatmentSketchs,
      SketchTemplate
    },
    data() {
      return {
        activeTab: 'FileAndPhotos',
      }
    },
    methods: {
      onTabChange(tab) {
        this.activeTab = tab;
      }
    },
    mounted() {
      this.onTabChange(this.activeTab);
    }
  }
</script>

<style scoped>
  #customer-summary .card-body {
    padding: .5rem !important;
  }
</style>
