import axios from './index'

export default {
  getCustomerSketchTemplates() {
    return axios.get('/customerSketch/getCustomerSketchTemplates')
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getCustomerSketches(customerId) {
    return axios.get('/customerSketch/GetCustomerSketches?customerId=' + customerId)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getBase64ForSketchTemplateId(sketchTemplateId) {
    return axios.get('/CustomerSketch/GetBase64ForSketchTemplateId?sketchTemplateId=' + sketchTemplateId)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getBase64ForCustomerSketchId(customerSketchId) {
    return axios.get('/CustomerSketch/GetBase64ForCustomerSketchId?sketchId=' + customerSketchId)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getAppointmentStaffForCustomerSketch(customerId) {
    return axios.get('/customerSketch/getAppointmentStaffForCustomerSketch?customerId=' + customerId)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  postCustomerSketch(sketch) {
    return axios.post('/customerSketch/insert', sketch)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  downloadTreatmentSketch(sketch, responseType) {
    return axios.post('/customerSketch/downloadTreatmentSketch', sketch, responseType)
      .then((response) => {
        return response;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
}
